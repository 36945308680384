<template>
  <div class="min-h-screen bg-lol-purple py-16">
    <div class="container mx-auto px-4">
      <div class="max-w-2xl mx-auto bg-white/10 backdrop-blur-sm rounded-xl p-8 text-center">
        <div class="mb-8">
          <svg class="w-20 h-20 mx-auto text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </div>
        
        <h1 class="text-4xl font-bold text-white mb-6">Booking Confirmed!</h1>
        
        <div class="space-y-4 text-white text-lg mb-8">
          <p>Thank you for choosing LOL Photo Booth for your special event!</p>
          <p>We have received your booking request and our team will contact you within 24 hours to confirm all details.</p>
          <p>A confirmation email has been sent to your provided email address.</p>
        </div>

        <div class="bg-white/5 rounded-lg p-6 mb-8">
          <h2 class="text-lol-pink text-2xl font-bold mb-4">What's Next?</h2>
          <ul class="text-white text-left space-y-3">
            <li>✓ Our team will review your booking details</li>
            <li>✓ We'll contact you to confirm availability</li>
            <li>✓ Discuss any specific requirements or customizations</li>
            <li>✓ Finalize the booking and payment details</li>
          </ul>
        </div>

        <div class="space-y-4">
          <router-link 
            to="/" 
            class="inline-block bg-lol-pink text-white px-8 py-3 rounded-full font-semibold hover:opacity-90 transition-opacity"
          >
            Return to Home
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookingConfirmation',
  mounted() {
    // 如果用户直接访问此页面（没有经过预订流程），重定向到首页
    if (!this.$route.params.fromBooking) {
      this.$router.push('/')
    }
  }
}
</script> 